<template>
  <v-container fluid>
    <div class="app">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >
          <div class="md-card md-theme-default">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Pending Order Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right">
                    <v-btn small @click="backToReports">
                      <v-icon small>mdi-backburger</v-icon>
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-row class="pl-6 pr-6">
                <v-col cols="2">
                  <v-autocomplete
                    v-model="searchParams.productTypeId"
                    outlined
                    background-color="#fff"
                    label="Product Type"
                    item-value="id"
                    item-text="name"
                    return-object
                    :items="['All', ...productTypes]"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="searchParams.venueServiceId"
                    outlined
                    background-color="#fff"
                    label="Services"
                    item-value="id"
                    item-text="name"
                    return-object
                    :items="['All', ...venueServices]"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="2" style="padding-right: 0">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        outlined
                        background-color="#fff"
                        class="text1"
                        v-model="date1Formatted"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParams.from_date"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="text2"
                        v-model="date2Formatted"
                        outlined
                        background-color="#fff"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParams.to_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-flex xs1 class="ml-1">
                    <v-btn color="#00b0af" height="48" dark @click="getReport"
                      >Go</v-btn
                    >
                  </v-flex>
                </v-col>

                <v-spacer></v-spacer>
                <v-col md="2" class="text-right">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          checkExportPermission($modules.reports.service.slug)
                        "
                        color="#00b0af"
                        height="48"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >Export Report</v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item @click="reportDownload('pdf')">
                        <v-list-item-icon>
                          <v-icon>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as PDF</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="reportDownload('excel')">
                        <v-list-item-icon>
                          <v-icon>mdi-file-excel-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as Excel</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="logTable">
                      <thead class="md-card-header">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Bill Time</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Invoice Number
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Invoice Date
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Service
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Products
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Price</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Tax</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Grand Total</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Days Overdue
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="openInvoiceReport.length">
                        <tr
                          class="md-table-row"
                          :key="`${lndex}`"
                          v-for="(data, lndex) in openInvoiceReport"
                        >
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.created_at | timeStamp }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              {{ data.customer_name ? data.customer_name : "" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.invoice_seq_no != null
                                  ? data.invoice_seq_no
                                  : "--"
                              }}
                            </div>
                          </td>

                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.order_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.service_name != null
                                  ? data.service_name
                                  : "--"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.products != null ? data.products : "--" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.price != null ? data.price : 0 | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.tax != null ? data.tax : 0 | toCurrency }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.total != null ? data.total : 0 | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.overdue != null
                                  ? data.overdue
                                  : 0 | numberFormatter
                              }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="7">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <v-pagination
                      class="mt-3"
                      v-model="page"
                      :length="totalPages"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      openInvoiceReport: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        productTypeId: null,
        from_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      totalPages: 0,
      page: 1,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.openInvoiceReport.length;
    },
    rowSpan() {
      return parseInt(this.openInvoiceReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/pending-order-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.openInvoiceReport = data.data;
            this.totalPages = data.total_pages;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "pending-order-report-pdf";
      } else if (type == "excel") {
        link = "pending-order-report-excel";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `/download` + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (
        this.searchParams.venueServiceId &&
        this.searchParams.venueServiceId.venue_service_id
      ) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (
        this.searchParams.productTypeId &&
        this.searchParams.productTypeId.id
      ) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Pending-Order-Report-" + this.fileName;
      else this.fileName = "Pending-Order-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function(opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
</style>
